import { createSlice } from "@reduxjs/toolkit";

const AdTypeSlice = createSlice({
  name: "adType",
  initialState: {
    AdTypeDetails: "",
  },
  reducers: {
    setAdTypeDetails: (state, action) => {
      state.AdTypeDetails = action.payload;
    },
  },
});

export const { setAdTypeDetails } = AdTypeSlice.actions;
export default AdTypeSlice.reducer;
